@if (loading()) {
  <p-skeleton
    class="mt-2"
    height="332px"
    styleClass="w-full"
  ></p-skeleton>
} @else {
  @if (customAttributes().length > 0) {
    <div class="grid grid-nogutter py-2 px-0_75rem mb-2">
      <div
        class="{{ withMandatoryColumn ? 'col-4' : 'col-5' }} text-bluegray-700 uppercase subheading"
      >
        Name
      </div>
      <div
        class="{{
          withMandatoryColumn ? 'col-4' : 'col-5'
        }} pl-3 text-bluegray-700 uppercase subheading"
      >
        Type
      </div>
      @if (withMandatoryColumn) {
        <div class="col-2 pl-3 text-bluegray-700 uppercase subheading">Mandatory</div>
      }
      <div class="col-2"></div>
    </div>
    <div class="relative">
      <div class="wrapper hidden-scrollbar flex-grow-1">
        @for (attribute of customAttributes() | sortBy: 'asc' : 'name'; track attribute.name) {
          <custom-attribute-row-item
            [customAttribute]="attribute"
            [withMandatoryColumn]="withMandatoryColumn"
            (edited)="editAttribute($event)"
            (deleted)="deleteAttribute($event)"
          ></custom-attribute-row-item>
        }
      </div>
    </div>
  } @else {
    <empty-data-block
      title="Currently there are no custom attributes"
      [showButton]="true"
      buttonLabel="Add New Attribute"
      (buttonClicked)="createAttribute()"
    ></empty-data-block>
  }
}

@if (processing()) {
  <pinnakl-local-spinner></pinnakl-local-spinner>
}
