import { inject, Injectable, signal } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { getErrorMessage } from '@pinnakl/shared/util-helpers';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { catchError } from 'rxjs';

export interface ClientDetails {
  adminSecurityLock: boolean;
}

// in case of service extending highly recommended to rewrite it to simple store
@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  private readonly wsp = inject(WebServiceProvider);
  private readonly toast = inject(PinnaklUIToastMessage);

  public readonly clientDetails = signal<ClientDetails | null>(null);
  public readonly clientDetailsLoading = signal<boolean>(false);
  public readonly clientDetailsLoaded = signal<boolean>(false);

  loadClientDetails(): void {
    if (this.clientDetails()) return;
    this.clientDetailsLoading.set(true);
    this.wsp
      .get<ClientDetails>({
        endpoint: `v4/clients`
      })
      .pipe(
        catchError(error => {
          this.clientDetailsLoading.set(false);
          this.clientDetailsLoaded.set(false);
          this.toast.error('Cannot load client details. ' + getErrorMessage(error));
          throw error;
        })
      )
      .subscribe(clientDetails => {
        this.clientDetails.set(clientDetails);
        this.clientDetailsLoading.set(false);
        this.clientDetailsLoaded.set(true);
      });
  }

  async updateClients(adminSecurityLock = false): Promise<void> {
    this.clientDetailsLoading.set(true);

    try {
      await this.wsp.putHttp({
        endpoint: `v4/clients`,
        body: { adminSecurityLock }
      });
      this.clientDetails.set({ adminSecurityLock });
      this.toast.success('Security Lock has been updated for users');
    } catch (error) {
      this.clientDetailsLoading.set(false);
      this.toast.error('Could not update Security Lock for users');
      throw error;
    } finally {
      this.clientDetailsLoading.set(false);
    }
  }
}
